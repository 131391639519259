// import package
import React from "react";
import { Routes, BrowserRouter, Route, Navigate } from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/aboutCMS";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Activity from "./pages/Activity";
import ItemDetails from "./pages/ItemDetails";
import EditProfile from "./pages/EditProfile";
import ConnectWallet from "./pages/ConnectWallet";
import Expolore from "./pages/Explore";
import Exclusive from "./pages/Exclusive";
import LiveAuction from "./pages/LiveAuction";
import Collections from "./pages/Collections";
import Myitems from "./pages/Myitems";
import Create from "./pages/Create";
import Ranking from "./pages/Ranking";
import Browse from "./pages/Browse";
import Support from "./pages/Support";
import MyCollections from "./pages/myowncollection";
import MyCollectiondetail from "./pages/mycollection";
import Myfavorites from "./pages/Myfavorities";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//
import { Provider } from "react-redux";
import store from "./store";
import { WagmiConfig, createConfig, configureChains, mainnet } from "wagmi";
import { bsc, bscTestnet, polygon, polygonMumbai, goerli } from "viem/chains";

import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";

import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { InjectedConnector } from "wagmi/connectors/injected";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet, goerli],
  [publicProvider()]
);
console.log({ chains }, "chainschainschainswwwww");

// Set up wagmi config
const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains, shimDisconnect: true }),

    new WalletConnectConnector({
      chains,
      options: {
        projectId: "681700e231a5aef269b7fe4adb34981a",
        version: "2",
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: "Injected",
        shimDisconnect: true,
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
});

const App = () => {
  return (
    <>
      <Provider store={store}>
        <WagmiConfig config={config}>
          <BrowserRouter basename="/">
            <ToastContainer />
            <Routes>
              <Route path="/edit-profile" element={<EditProfile />} />
              <Route path="/about" element={<About />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/Browse" element={<Browse />} />
              <Route path="/ranking" element={<Ranking />} />
              <Route path="/create" element={<Create />} />
              <Route path="/mynfts" element={<Myitems />} />
              <Route path="/user/:paramAddress" element={<Myitems />} />
              <Route path="/collections" element={<Collections />} />
              <Route path="/mycollections" element={<MyCollections />} />
              <Route path="/collections/:id" element={<Collections />} />
              <Route
                path="/mycollections/:id"
                element={<MyCollectiondetail />}
              />
              <Route path="/myfavorites" element={<Myfavorites />} />
              <Route path="/explore" element={<Expolore />} />
              <Route path="/exclusive" element={<Exclusive />} />
              <Route path="/live-auction" element={<LiveAuction />} />
              <Route path="/connect-wallet" element={<ConnectWallet />} />
              <Route
                path="/item-details/:tokenidval"
                element={<ItemDetails />}
              />
              <Route path="/activity" element={<Activity />} />
              <Route path="/support" element={<Support />} />
              <Route path="/" element={<Home />} />
            </Routes>
          </BrowserRouter>
        </WagmiConfig>
      </Provider>
    </>
  );
};

export default App;
